import React, { useState } from "react"
import { device } from "../utils/devices"
import Layout from "../components/layout"
import poster1 from "../images/poster.png"
import dec30 from "../images/dec-30.jpeg"
import howToPlay from "../images/howtoplay.jpg"
import SEO from "../components/seo"
import styled from "styled-components"
import Modal from "../components/Modal"
import cuff from "../images/cuff.jpg"
import insecure from "../images/insecure.jpg"
import EventbriteButton from "react-eventbrite-popup-checkout"

const Wrapper = styled.div`
  text-align: center;
  padding: 5vw;
  margin-top: 1rem;

  h2 {
    grid-column: span 3;
    text-transform: uppercase;
    color: white;
    margin: 0 0 1rem;

    @media ${device.laptop} {
      margin: 0;
    }
  }

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5vw;
    margin: 0 auto;
    width: 80vw;
    padding: 0;
  }

  a {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    color: black;
  }

  img {
    max-height: 80vh;
    margin-bottom: 2rem;
    display: block;
    width: 100%;
  }
`

const Intro = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 5vw;

  button {
    background: rgb(94, 207, 55);
    color: white;
    border: 0;
    appearance: none;
    border-radius: 6px;
    font-weight: bold;
    padding: 10px 16px;
    font-size: 20px;
    min-width: 100px;
    outline: none;
    font-family: "CH", sans-serif;
    transition: 0.2s all ease-in-out;
    cursor: pointer;

    @media ${device.laptop} {
      padding: 20px 32px;
      font-size: 30px;
      margin-bottom: 2rem;
    }

    &:hover {
      transform: translate(0, -2px);
    }
  }

  h3 {
    color: #fac516;
    text-transform: uppercase;
    text-align: center;
  }

  h1 {
    color: white;
    text-transform: uppercase;
    text-align: center;
  }

  div {
    p {
      text-align: center;
      font-weight: bold;
      color: white;
      margin: 0;
    }
  }
`

const Button = styled.button`
  background: rgb(94, 207, 55);
  color: white;
  border: 0;
  appearance: none;
  border-radius: 6px;
  font-weight: bold;
  padding: 10px 16px;
  font-size: 20px;
  min-width: 100px;
  outline: none;
  font-family: "CH", sans-serif;
  transition: 0.2s all ease-in-out;
  cursor: pointer;

  @media ${device.laptop} {
    padding: 20px 32px;
    font-size: 30px;
    margin-bottom: 2rem;
  }

  &:hover {
    transform: translate(0, -2px);
  }
`

const Poster = styled.div`
  img {
    @media ${device.laptop} {
      height: 70vh;
    }
  }
`

const IndexPage = ({ openModal }) => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Layout>
      <SEO>
        <title>Home | Make it Make Sense</title>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta property="type" content="website" />
        <meta property="url" content="https://mims.live" />
        <meta name="msapplication-TileColor" content="#30a4d5" />
        <meta name="theme-color" content="#30a4d5" />
        <meta property="title" content="Make it Make Sense" />
        <meta
          name="description"
          content="Trap Karaoke presents “Make It Make Sense” (MIMS), the live game show where you can win ca$h for your captions🤑."
        />
        <meta property="image" content={cuff} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Make it Make Sense" />
        <meta property="og:hashtag" content="MakeItMakeSense" />
        <meta property="og:image" content={cuff} />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content="https://mims.live" />
        <meta property="og:site_name" content="TrapKaraoke" />
        <meta
          property="og:description"
          content="Trap Karaoke presents “Make It Make Sense” (MIMS), the live game show where you can win ca$h for your captions🤑."
        />
      </SEO>
      <Intro>
        <Poster>
          <img src={insecure} />
        </Poster>
        <br />
        {typeof window !== "undefined" && (
          <EventbriteButton ebEventId="196823613727">RSVP </EventbriteButton>
        )}
      </Intro>
      <Wrapper>
        <h2>Season 1</h2>
        <img src={dec30} />
        <img src={cuff} />
        <img src={poster1} />
      </Wrapper>
      {modalOpen && <Modal onClose={() => setModalOpen(false)} />}
    </Layout>
  )
}

export default IndexPage
