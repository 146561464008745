import React, { useState } from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { device } from "../utils/devices"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(48, 167, 217, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Inner = styled.div`
  background: white;
  border-radius: 6px;
  padding: 2rem;
  width: 90vw;
  max-width: 600px;
  position: relative;

  h3 {
    text-transform: uppercase;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;

    input {
      font-size: 16px;
      padding: 0.5rem;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
`

const Button = styled.button`
  background: #ed2123;
  color: white;
  border: 0;
  appearance: none;
  border-radius: 6px;
  font-weight: bold;
  padding: 10px 16px;
  font-size: 20px;
  min-width: 100px;
  outline: none;
  font-family: "CH", sans-serif;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  width: 100%;

  @media ${device.laptop} {
    padding: 20px 32px;
    font-size: 30px;
  }

  &:hover {
    transform: translate(0, -2px);
  }
`

const Success = styled.div``

const Close = styled.div`
  position: absolute;
  bottom: -2rem;
  right: 0;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
`

const Modal = ({ open, onClose }) => {
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then(data => {
        setSuccess(true)
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        console.log(data)
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      })
  }

  return (
    <Wrapper>
      <Inner>
        <h3>{success ? `Step 2` : `Step 1`}</h3>
        {success ? (
          <Success>
            <a
              href="https://add.eventable.com/events/5f7acd436c4df9001aebd45e/5fd985af2c455a00bfc8d79c"
              data-event="5fd985af2c455a00bfc8d79c"
              class="eventable-link"
              target="_blank"
              data-key="5fd985af2c455a00bfc8d79c"
              data-style="1"
            >
              {" "}
              <Button>Add to Calendar</Button>
            </a>
          </Success>
        ) : (
          <>
            <form
              name="rsvp"
              method="post"
              data-netlify="true"
              onSubmit={handleSubmit}
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="rsvp" />
              <br />
              <input
                name="email"
                onChange={e => setEmail(e.target.value)}
                placeholder="Email Address"
                type="email"
                value={email}
              />
              <br />

              <Button htmlType="submit">RSVP</Button>
            </form>
          </>
        )}
        <Close onClick={onClose}>Close</Close>
      </Inner>
    </Wrapper>
  )
}

export default Modal
